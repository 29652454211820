<template>
  <main>
    <section id="products" class="products">
      <div class="title">
        <h1>Produkty</h1>
      </div>
    </section>
    <section class="procucts-categories p-84 mt-68">
            <div class="product">
              <div class="product-img">
                <img src="../assets/img/urnovehroby.jpg" alt="Urnové hroby" title="Urnové hroby">
                <h3>Urnové hroby</h3>
              </div>
              <div class="more-info">
                <router-link to="/produkty/zula#zula">Viac informácií</router-link>
              </div>
            </div>
            <div class="product">
              <div class="product-img">
                <img src="../assets/img/jednopomniky.jpg" alt="Jednopomniky" title="Jednopomniky">
                <h3>Jednopomniky</h3>
              </div>
              <div class="more-info">
                <router-link to="/produkty/jednopomniky#jednopomniky">Viac informácií</router-link>
              </div>
            </div>
            <div class="product">
              <div class="product-img">
                <img src="../assets/img/dvojpomniky.jpg" alt="Dvojpomníky" title="Dvojpomníky">
                <h3>Dvojpomníky</h3>
              </div>
              <div class="more-info">
                <router-link to="/produkty/dvojpomniky#dvojpomniky">Viac informácií</router-link>
              </div>
            </div>
            <div class="product">
              <div class="product-img">
                <img src="../assets/img/namieru.jpg" alt="Na mieru" title="Na mieru">
                <h3>Na mieru</h3>
              </div>
              <div class="more-info">
                <router-link to="/produkty/namieru#namieru">Viac informácií</router-link>
              </div>
            </div>
            <div class="product">
              <div class="product-img">
                <img src="../assets/img/domacnost.jpg" alt="Domácnosť" title="Domácnosť">
                <h3>Domácnosť</h3>
              </div>
              <div class="more-info">
                <router-link to="/produkty/domacnost#domacnost">Viac informácií</router-link>
              </div>
            </div>
      <router-view></router-view>
    </section>    
  </main>
</template>

<script>
export default {
  name: 'Products',
}
</script>

<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.products{
  @include overlay;
  @include views-title;
}

.procucts-categories{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  .product-img{
    @include overlay;
    @include ripped;
    h3{
      color: $white;
      font-size: 20px;
      font-weight: 700;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 15;
      width: 100%;
      transform: translate(-50%, -50%);
    }
    img{
      width: 100%;
      height: 180px;
    }
  }
  .more-info{
     a{
      @include buttons;
    }
  }
}
</style>
